<template>
  <div class="admission-page" id="admission-page">
    <v-row>
      <v-col cols="12">
        <h2 class="admission-title" :class="{ hidden: success }">
          {{ $t("Student Admission Form 2023-2024") }}
        </h2>
      </v-col>
      <v-col cols="12" sm="6" class="svg-sec px-0 text-center">
        <div class="">
          <svg
            version="1.1"
            id="e7b3b0c3-1e4f-480c-906c-c03021e90d10"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 991.1 770.2"
            style="enable-background: new 0 0 991.1 770.2"
            xml:space="preserve"
          >
            <title>{{ $t("professor") }}</title>
            <path
              class="st0"
              d="M289.6,0L131.7,85.5c11.1,19.2,4.6,43.8-14.6,54.9C98,151.5,73.4,145,62.3,125.8c-0.4-0.7-0.8-1.4-1.1-2.1
	L7.3,152.9l139.4,257.4L429,257.4L289.6,0z"
            />
            <circle class="st1" cx="94.6" cy="105.7" r="32.6" />
            <path
              class="st0"
              d="M686,689.1l170.7-55.7c-7.5-20.9,3.3-43.9,24.2-51.4s43.9,3.3,51.4,24.2c0.3,0.8,0.5,1.5,0.7,2.3l58.2-19
	l-90.8-278.3l-305.2,99.5L686,689.1z"
            />
            <circle class="st1" cx="896.8" cy="620.2" r="32.6" />
            <rect x="115.5" y="152.7" class="st2" width="788" height="358" />
            <rect x="128.5" y="165.7" class="st3" width="762" height="325" />
            <rect x="739" y="475.2" class="st2" width="74" height="25" />
            <rect x="681" y="494.2" class="st1" width="54" height="7" />
            <polyline
              class="st4"
              points="190.5,288.7 481.5,288.7 526.6,310.7 598.5,345.7 645.5,257.7 778.5,257.7 "
            />
            <polyline
              class="st4"
              points="304.9,288.7 326.5,339.7 379.5,339.7 "
            />
            <polyline
              class="st4"
              points="385.8,288.7 459.5,248.7 511.5,248.7 "
            />
            <circle class="st1" cx="304.5" cy="288.7" r="9" />
            <circle class="st1" cx="388.5" cy="288.7" r="9" />
            <polyline
              class="st4"
              points="526.5,310.7 532.5,399.7 584.5,399.7 "
            />
            <polyline class="st4" points="626.7,293 569.5,248.7 623.5,248.7 " />
            <polyline
              class="st4"
              points="721.1,257.7 776.5,301.7 828.5,301.7 "
            />
            <circle class="st1" cx="526.5" cy="310.7" r="9" />
            <circle class="st1" cx="720.5" cy="257.7" r="9" />
            <circle class="st1" cx="626.5" cy="292.7" r="9" />
            <line class="st4" x1="327.5" y1="347.7" x2="379.5" y2="347.7" />
            <path class="st4" d="M511.5,257.7L511.5,257.7z" />
            <path class="st4" d="M623.5,256.7L623.5,256.7z" />
            <path class="st4" d="M623.5,264.7L623.5,264.7z" />
            <path class="st4" d="M828.5,309.7L828.5,309.7z" />
            <path class="st4" d="M809.5,317.7L809.5,317.7z" />
            <path class="st4" d="M584.5,407.7L584.5,407.7z" />
            <circle class="st4" cx="809.5" cy="75.5" r="14.8" />
            <ellipse class="st0" cx="152" cy="742.2" rx="152" ry="28" />
            <polygon
              class="st1"
              points="256.4,493.7 229.3,507.3 231,510.8 258.1,497.2 "
            />
            <path
              class="st5"
              d="M252,468.2l-1.2,7.3c0,0,1.8,50.6-11.6,34.7s-4.3-37.2-4.3-37.2l3.7-6.1L252,468.2z"
            />
            <path
              class="st5"
              d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
            />
            <path
              class="st6"
              d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
            />
            <polygon
              class="st2"
              points="128.9,700.9 128.9,722.8 120.4,724 108.2,720.4 111.9,697.2 "
            />
            <path
              class="st7"
              d="M118,711.9c0,0-3.7-9.7-9.7-8.5s-7.3,8.5-7.3,8.5s-4.9,18.3-12.2,24.4s-14.6,21.9,0,24.4s26.8-3.7,26.8-4.9
	s3.7-8.5,9.7-9.7c6.1-1.2,11-2.4,11-7.3s-3.7-9.7-3.7-9.7s-1.2-23.1-3.7-20.1c-1.7,2.4-2.9,5.1-3.7,7.9L118,711.9z"
            />
            <polygon
              class="st2"
              points="181.3,689.9 176.4,708.2 188.6,710.6 194.7,704.5 195.9,688.7 "
            />
            <path
              class="st7"
              d="M180.1,705.8c0,0,4.9-3.7,7.3-3.7h3.7c0,0,0-8.5,6.1-8.5s4.9,6.1,4.9,6.1s13.4,20.7,17.1,24.4
	s23.1,14.6,7.3,19.5s-26.8,4.9-34.1-3.7c0,0-7.3-3.7-12.2-3.7s-11-2.4-11-6.1s3.7-11,3.7-11s2.4-21.9,3.7-19.5
	S180.1,705.8,180.1,705.8z"
            />
            <path
              class="st7"
              d="M127.7,409.7v40.2c0,0-13.4,78-13.4,103.5s-20.7,147.4-11,149.8s32.9,6.1,34.1,2.4s9.7-119.4,11-120.6
	c1.2-1.2,26.8-97.5,26.8-97.5l4.9,1.2l2.4,109.6c0,0-12.2,98.7-6.1,98.7s31.7-1.2,31.7-8.5s13.4-142.5,13.4-142.5
	S237.4,439,231.3,428s-6.1-17.1-6.1-17.1L127.7,409.7z"
            />
            <circle class="st5" cx="194.1" cy="228.8" r="24.4" />
            <path
              class="st8"
              d="M188.6,270.9c0,0-15.2-12.8-16.4-17.7c0,0-7.9,7.9-7.9,9.1s-34.1,4.9-42.6,14.6s-15.8,30.5-15.8,30.5l20.7,39
	c0,0,1.2,21.9,1.2,24.4s-6.1,41.4-3.7,42.6s26.8,13.4,47.5,11s41.4-6.1,47.5-6.1s11,2.4,11,0s-3.7-7.3-3.7-9.7s-1.2-8.5-1.2-12.2
	s-2.4-9.7-2.4-14.6s26.8-70.7,26.8-70.7s-1.2-20.7-20.7-30.5s-21.9-13.4-21.9-13.4s-0.4-7.3-1.4-7.3s-4.7,11-14.4,12.2L188.6,270.9z
	"
            />
            <polygon
              class="st5"
              points="122.8,432.9 131.4,446.3 153.3,441.4 137.5,423.1 "
            />
            <path
              class="st8"
              d="M242.2,301.3l7.3,9.7c0,0,14.6,70.7,12.2,96.2s-4.9,45.1-4.9,45.1s4.9,18.3,1.2,18.3s-25.6,1.2-25.6-2.4
	s2.4-26.8,2.4-26.8l-6.1-53.6l-13.4-18.3L242.2,301.3z"
            />
            <path
              class="st7"
              d="M177.6,217c1.5-0.6,3.2-0.1,4.8-0.4c1.9-0.3,3.6-1.7,5.5-1.5c0.9,0.2,1.8,0.5,2.6,0.8c0.8,0.4,1.8,0.4,2.7,0.1
	c0.8-0.4,1.1-1.3,0.7-2c0-0.1-0.1-0.2-0.2-0.3c1.6-0.1,3.1-0.8,4.3-1.9c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.1,2.1,0.3l2.8,1.3
	c0.6,0.2,1.2,0.6,1.6,1.1c0.3,0.5,0.4,1.2,0.9,1.6c0.6,0.5,1.5,0.3,2.3,0.3c1.5,0.1,2.6,1.4,2.6,2.8c0,0.8-0.3,1.8,0.3,2.3
	c0.4,0.3,0.9,0.2,1.3,0.4c1.1,0.5,1.1,2,1,3.3s-0.1,2.8,1.1,3.2c0.8,0.3,1.7-0.3,2.5-0.4c1.3-0.2,2.5,0.6,3.6,1.2s2.7,1,3.7,0.2
	c0.9-0.8,0.9-2.2,0.6-3.4c-0.4-1.7-1-3.3-1.8-4.9c-0.3-0.5-0.5-1-0.5-1.5c0.1-0.8,0.6-1.5,1.3-1.9c1.4-1.1,3-2.2,3.5-4
	c0.3-1.3,0-2.6,0-3.9c0-1.6,0.7-3.2,0.4-4.8c-0.5-2.9-3.7-4.2-5.8-6.3c-2.8-2.8-3.7-7.4-7.1-9.6c-2.7-1.8-6.1-1.6-9.2-2.6
	c-3.3-1.1-6-3.7-9.3-4.9c-3.6-1.3-7.7-1-11,1c-1,0.7-2.1,1.3-3.2,1.7c-1,0.1-2,0.1-2.9,0c-1.9-0.1-3.9,0.3-5.6,1.2
	c-0.7,0.3-1.2,0.8-1.6,1.4c-0.6,1.3,0.3,3.4-1,4c-0.6,0.3-1.3,0-2,0c-1.3,0-2.3,1.2-2.9,2.3c-0.5,1.2-1.2,2.3-2,3.3
	c-1.6,1.4-4.5,1.6-4.9,3.7c0,0.9,0,1.8,0.1,2.6c-0.1,1.8-2.1,3.2-2,5.1c0.1,2.1,2.8,3.2,3.5,5.1c0.5,1.5-0.2,3.2-0.7,4.7
	s-0.6,3.5,0.6,4.5c0.5,0.4,1.1,0.5,1.7,0.9c1.3,1,1.3,2.9,1.2,4.5c-0.2,0.7,0,1.4,0.5,1.9c0.4,0.3,1,0.3,1.5,0.2
	c1.6-0.4,3-1.4,3.8-2.9c0.4-0.7,0.5-1.3,1.3-1.7c0.5-0.3,1.2,0,1.7-0.4C175.3,222.8,174.4,218.3,177.6,217z"
            />
            <path
              class="st8"
              d="M109.4,303.7l-3.7,3.7c0,0-21.9,65.8-14.6,80.4s21.9,51.2,26.8,49.9s21.9-12.2,21.9-13.4S119.2,389,119.2,389
	s-2.4-7.3-1.2-8.5s1.2,0,0-2.4s-1.2,0,1.2-2.4s2.4-2.4,2.4-3.7s7.3-31.7,7.3-31.7L109.4,303.7z"
            />
            <polygon
              class="st7"
              points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
            />
            <polygon
              class="st6"
              points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
            />
            <polygon class="st6" points="164.9,262.9 185.6,280 188,276.3 " />
            <polygon class="st6" points="206.3,267.8 191.7,276.3 192.9,280 " />
          </svg>
        </div>
      </v-col>
      <v-col class="side-page" cols="12" sm="6">
        <p style="text-align: right; color: white; font-size: 20px">
          <span :class="{ hidden: success }">
            {{ $t("للتقديم يرجى تعبئه النموذج أدناه") }}<br />
          </span>
          {{
            $t("لمزيد من المعلومات عن اكاديمية عالم جدة والمراحل والمصروفات")
          }}
          <a
            href="https://jwa.edu.sa/?lang=ar"
            target="_blank"
            style="font-weight: bold"
            class="secondary-color"
          >
            {{ $t("اضغط هنا") }}
          </a>
        </p>
        <p style="text-align: left; color: white; font-size: 20px">
          <span :class="{ hidden: success }">
            To apply please fill the form below <br />
          </span>
          for more details about Jeddah world academy, grades and tuition fees

          <a
            href="https://jwa.edu.sa"
            target="_blank"
            style="font-weight: bold"
            class="secondary-color"
          >
            click here
          </a>
        </p>
      </v-col>
    </v-row>

    <v-row class="admission-stepper" :class="{ hidden: success }">
      <v-col cols="12" sm="8" offset-sm="2">
        <v-stepper v-model="step" alt-labels>
          <v-stepper-header>
            <v-stepper-step
              class="clickable"
              :complete="step > 1"
              step="1"
              @click="nextStep(1)"
            >
              {{ $t("Student Information") }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class="clickable"
              :complete="step > 2"
              step="2"
              @click="nextStep(2)"
            >
              {{ $t("Academic Information") }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step class="clickable" step="3" @click="nextStep(3)">
              {{ $t("Contact Person Details") }}
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <!-- <center>
          <a href="http://jwa.edu.sa"
            ><img
              v-if="logo != 'null'"
              :src="logo"
              style="border-radius: 50%; width: 50px; height: 50px"
              target="_blank"
            />
            <img
              v-if="logo == 'null'"
              src="../../assets/school_logo.png"
              style="border-radius: 50%; width: 50px; height: 50px"
              target="_blank"
            />
          </a>
        </center> -->
        <p
          style="text-align: center; color: black; font-size: 20px"
          :hidden="$vuetify.breakpoint.mdAndUp"
        >
          <span :class="{ hidden: success }"
            >{{ $t("To apply, please fill out the following form") }}<br />
          </span>
          {{ $t("For more information about the school, grades and fees") }}
          <a
            href="https://jwa.edu.sa/?lang=ar"
            target="_blank"
            style="font-weight: bold"
            class="secondary-color"
          >
            {{ $t("click here") }}
          </a>
        </p>
        <p
          style="text-align: center; color: black; font-size: 20px"
          :hidden="$vuetify.breakpoint.mdAndUp"
        >
          <span :class="{ hidden: success }"
            >{{ $t("To apply please fill the form below") }} <br
          /></span>
          {{
            $t(
              "for more details about Jeddah world academy, grades and tuition fees"
            )
          }}
          <a
            href="https://jwa.edu.sa"
            target="_blank"
            style="font-weight: bold"
          >
            {{ $t("click here") }}
          </a>
        </p>
        <br />
        <div class="message" :class="{ hidden: !success }">
          <div class="success--text text-center">
            <span class="success-icon">
              <i class="fas fa-check-circle"></i>
            </span>
            <h2>{{ successMsg }}</h2>
          </div>

          <p>
            <strong>{{ $t("Applicant Name") }}: </strong>{{ applicant_name }}
          </p>
          <p>
            <strong> {{ $t("Application Number") }}:</strong> {{ requestNum }}
          </p>
        </div>

        <v-form
          class="admission-form"
          id="admission-form"
          :class="{ hidden: success }"
          v-model="valid1"
          ref="form1"
        >
          <section
            class="form-page"
            id="page1"
            :class="{ displayFormSection: page1 }"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="pa-0 left-sec"
                :class="currentAppLocale == 'ar' ? 'right-text' : 'left-text'"
              >
                <h4
                  class="studentInfo-title"
                  :class="currentAppLocale == 'ar' ? 'right-text' : 'left-text'"
                >
                  {{ $t("Student Information") }}
                </h4>

                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="first-name"
                      >{{ $t("First Name") }} {{ $t("English") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="first-name"
                      v-model.trim="admission.en.student_first_name"
                      @focus="onFocus()"
                      solo
                      dense
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                        validationRules.spaces,
                      ]"
                      autocomplete="off"
                    ></v-text-field>

                    <div v-if="validation_errors.en">
                      <p
                        class="error--text"
                        v-for="(error, index) in validation_errors.en
                          .student_first_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="second-name"
                      >{{ $t("Father's Name") }} {{ $t("English") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="second-name"
                      v-model.trim="admission.en.student_father_name"
                      @focus="onFocus()"
                      solo
                      dense
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                      ]"
                    ></v-text-field>

                    <div v-if="validation_errors.en">
                      <p
                        class="error--text"
                        v-for="(error, index) in validation_errors.en
                          .student_father_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="second-name"
                      >{{ $t("GrandFather Name") }} {{ $t("English") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="grandFather-name"
                      v-model.trim="admission.en.student_grand_father_name"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="family-name"
                      >{{ $t("Family Name") }} {{ $t("English") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="family-name"
                      v-model.trim="admission.en.student_family_name"
                      @focus="onFocus()"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                        validationRules.spaces,
                      ]"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.en">
                      <p
                        class="error--text"
                        v-for="(error, index) in validation_errors.en
                          .student_family_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="geo-date"
                      >{{ $t("DATE OF BIRTH:(DD/MM/YYYY)") }}
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="gregorian"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.lazy="admission.student_birthdate_m"
                      :rules="[validationRules.required]"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>

                    <div v-if="validation_errors.student_birthdate_m">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_birthdate_m"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label>{{ $t("PLACE OF BIRTH") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="place-birth"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.trim="admission.student_place_of_birth"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength50,
                        validationRules.spaces,
                      ]"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.student_place_of_birth">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_place_of_birth"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="nationality-options"
                      >{{ $t("Nationality") }}
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      :items="dataObject.nationalities"
                      item-text="name"
                      item-value="id"
                      v-model.trim="admission.student_nationality_id"
                      :select="onSelect()"
                      :rules="[validationRules.required]"
                      solo
                    >
                    </v-autocomplete>
                    <div v-if="validation_errors.student_nationality_id">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_nationality_id"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!saudi">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Passport Number") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="pass-num"
                      v-model.trim="admission.student_passport_no"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.student_passport_no">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_passport_no"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!saudi">
                  <v-col cols="12" md="4"
                    ><label>{{ $t("Iqama No") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="iqama-number"
                      v-model.trim="admission.student_iqama_no"
                      :rules="[
                        validationRules.required,
                        validationRules.alphaNum,
                        validationRules.spaces,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.student_iqama_no">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_iqama_no"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!saudi">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Expiry Date") }}</label>
                  </v-col>
                  <v-col cols="12" md="8" style="margin-top: -10px">
                    <v-radio-group v-model="student_iqama_no_date_type">
                      <v-row>
                        <v-col cols="6" md="6" sm="6"
                          ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                        ></v-col>
                        <v-col cols="6" md="6" sm="6"
                          ><v-radio
                            value="gregorian"
                            :label="$t('Gregorian')"
                          ></v-radio
                        ></v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="4" style="margin-top: -20px"></v-col>
                  <v-col cols="12" md="8" style="margin-top: -20px">
                    <v-text-field
                      id="iqama_geo"
                      @focus="onFocus()"
                      v-show="student_iqama_no_date_type == 'gregorian'"
                      autocomplete="off"
                      v-model.trim="admission.student_iqama_no_expire_date"
                      :rules="[validationRules.required]"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>

                    <v-text-field
                      id="hijri2"
                      v-show="student_iqama_no_date_type == 'hijri'"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.lazy="
                        admission.student_iqama_no_expire_date_hijri
                      "
                      :rules="[validationRules.required]"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>

                    <div v-if="validation_errors.student_iqama_no_expire_date">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_iqama_no_expire_date"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="religion-options">{{ $t("Religion") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-radio-group
                      v-model="admission.student_religion"
                      :rules="[validationRules.required]"
                      row
                    >
                      <v-radio :label="$t('Muslim')" value="muslim"></v-radio>
                      <v-radio
                        :label="$t('Non-muslim')"
                        value="non-muslim"
                      ></v-radio>
                    </v-radio-group>

                    <div v-if="validation_errors.student_religion">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_religion"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <!-- <label for="upload-photo">Upload Photo</label>
            <div class="custom-file" id="upload-photo">
              <input type="file" class="custom-file-input" id="customFile" />
              <label class="custom-file-label" for="customFile"
                >Drag and drop file here or <br
              /></label>
            </div> -->
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 right-sec">
                <h4
                  class="studentInfo-title"
                  style="visibility: hidden"
                  :class="currentAppLocale == 'ar' ? 'right-text' : 'left-text'"
                >
                  {{ $t("Student Information") }}
                </h4>
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="family-name"
                      >{{ $t("First Name") }} {{ $t("Arabic") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="first-name-ar"
                      v-model.trim="admission.ar.student_first_name"
                      @focus="onFocus()"
                      solo
                      dense
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                        CheckArabicCharactersOnly(
                          admission.ar.student_first_name
                        ),
                        validationRules.spaces,
                      ]"
                    ></v-text-field>

                    <div v-if="validation_errors.ar">
                      <p
                        class="error--text"
                        v-for="(error, index) in validation_errors.ar
                          .student_first_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="family-name"
                      >{{ $t("Father's Name") }} {{ $t("Arabic") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="father-name-ar"
                      v-model.trim="admission.ar.student_father_name"
                      @focus="onFocus()"
                      solo
                      dense
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                        CheckArabicCharactersOnly(
                          admission.ar.student_father_name
                        ),
                      ]"
                    ></v-text-field>

                    <div v-if="validation_errors.ar">
                      <p
                        class="error--text"
                        v-for="(error, index) in validation_errors.ar
                          .student_father_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="grandFather-name"
                      >{{ $t("GrandFather Name") }} {{ $t("Arabic") }}</label
                    >
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="grandFather-name"
                      v-model.trim="admission.ar.student_grand_father_name"
                      @focus="onFocus()"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                        CheckArabicCharactersOnly(
                          admission.ar.student_grand_father_name
                        ),
                      ]"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="family-name">
                      {{ $t("Family Name") }} {{ $t("Arabic") }}</label
                    ></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="family-name-ar"
                      v-model.trim="admission.ar.student_family_name"
                      @focus="onFocus()"
                      solo
                      dense
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength25,
                        CheckArabicCharactersOnly(
                          admission.ar.student_family_name
                        ),
                        validationRules.spaces,
                      ]"
                    ></v-text-field>

                    <div v-if="validation_errors.ar">
                      <p
                        class="error--text"
                        v-for="(error, index) in validation_errors.ar
                          .student_family_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="hijri-date">{{
                      $t("DATE OF BIRTH Hijri: (DD/MM/YYYY)")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="hijri"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.lazy="admission.student_birthdate_h"
                      :rules="[validationRules.required]"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>

                    <div v-if="validation_errors.student_birthdate_h">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_birthdate_h"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="gender">{{ $t("Gender") }}</label>
                  </v-col>
                  <v-col cols="12" md="8"
                    ><div class="">
                      <v-radio-group
                        v-model="admission.student_gender"
                        :rules="[validationRules.required]"
                        row
                      >
                        <v-radio :label="$t('Male')" value="male"></v-radio>
                        <v-radio :label="$t('Female')" value="female"></v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="validation_errors.student_gender">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_gender"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div></v-col
                  >
                </v-row>

                <v-row class="form-item" v-if="saudi">
                  <v-col cols="12" md="4">
                    <label for="national-id">{{
                      $t("National ID")
                    }}</label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="national-id"
                      v-model.trim="admission.student_national_id"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.student_national_id">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_national_id"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                    <div
                      v-if="
                        admission.student_national_id != '' &&
                        admission.student_national_id ==
                          admission.contact_national_id
                      "
                    >
                      <p class="error--text">
                        {{ $t("This national id aleardy exist") }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </section>
        </v-form>

        <v-form
          class="admission-form"
          id="admission-form"
          :class="{ hidden: success }"
          v-model="valid2"
          ref="form2"
        >
          <section
            class="form-page"
            id="page2"
            :class="{ displayFormSection: page2 }"
          >
            <h4
              class="studentInfo-title"
              :class="currentAppLocale == 'ar' ? 'right-text' : 'left-text'"
            >
              {{ $t("Academic Information") }}
            </h4>

            <v-row>
              <v-col
                class="pa-0 left-sec"
                cols="12"
                sm="6"
                :class="currentAppLocale == 'ar' ? 'right-text' : 'left-text'"
              >
                <v-row class="form-item grade">
                  <v-col cols="12" md="4"
                    ><label for="grade-level">{{
                      $t("Transferred to Grade")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="dataObject.grades"
                      item-text="grade_name"
                      item-value="id"
                      id="grade-level"
                      v-model.trim="admission.student_grade_level"
                      :rules="[validationRules.required]"
                      @change="checkGrade"
                      dense
                      solo
                    ></v-select>
                    <p
                      class="error--text"
                      v-if="gradeError"
                      :class="gradeError ? '' : 'hidden'"
                    >
                      {{ $t(errorOpenGradeMessage) }}
                    </p>

                    <div v-if="validation_errors.student_grade_level">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_grade_level"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="form-item native">
                  <v-col cols="12" md="4"
                    ><label for="native-language">{{
                      $t("Native Language")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="languages"
                      item-text="key"
                      item-value="value"
                      id="native-language"
                      v-model.trim="admission.student_native_language"
                      :rules="[validationRules.required]"
                      dense
                      solo
                    ></v-select>

                    <div v-if="validation_errors.student_native_language">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_native_language"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item secondLang">
                  <v-col cols="12" md="4"
                    ><label for="second-language">{{
                      $t("Second Language")
                    }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="languages"
                      item-text="key"
                      item-value="value"
                      id="second-language"
                      v-model.trim="admission.student_second_language"
                      dense
                      solo
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pa-0 right-sec" cols="12" sm="6">
                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="curriculum">{{ $t("Curriculum") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="Curriculum"
                      @focus="onFocus()"
                      v-model.trim="admission.student_curriculum"
                      :rules="[validationRules.maxLength50]"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.student_curriculum">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_curriculum"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="pre-school">{{ $t("Previous School") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="pre-school"
                      v-model.trim="admission.student_previous_school"
                      :rules="[validationRules.maxLength50]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div v-if="validation_errors.student_previous_school">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.student_curriculum"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </section>
        </v-form>

        <v-form
          class="admission-form"
          id="admission-form"
          :class="{ hidden: success }"
          v-model="valid3"
          ref="form3"
        >
          <section
            class="form-page"
            id="page3"
            :class="{ displayFormSection: page3 }"
          >
            <h4
              class="studentInfo-title"
              :class="currentAppLocale == 'ar' ? 'right-text' : 'left-text'"
            >
              {{ $t("Contact Person Details") }}
            </h4>
            <v-row>
              <v-col cols="12" sm="6" class="pa-0 left-sec">
                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="contact-name">{{ $t("Name") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="contact-name"
                      v-model.trim="admission.contact_person_name"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength50,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div
                      v-if="validation_errors.contact_person_name"
                      :class="
                        currentAppLocale == 'ar' ? 'right-text' : 'left-text'
                      "
                    >
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_person_name"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item mobile-div">
                  <v-col cols="12" md="4"
                    ><label for="mobile">{{ $t("Mobile Number") }}</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col xs="5" sm="5">
                        <div
                          class="code applicant-country-code"
                          id="country-code"
                        >
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            value="187"
                            v-model="admission.country_code"
                            ><template slot="selection" slot-scope="data">
                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col>
                      <v-col xs="7" sm="7">
                        <v-text-field
                          id="mobile"
                          v-model.trim="admission.contact_person_mobile"
                          @focus="onFocus()"
                          solo
                          dense
                          :rules="[
                            validationRules.required,
                            mobileRule(
                              admission.country_code,
                              admission.contact_person_mobile
                            ),
                          ]"
                        ></v-text-field>

                        <div
                          v-if="validation_errors.contact_person_mobile"
                          :class="
                            currentAppLocale == 'ar'
                              ? 'right-text'
                              : 'left-text'
                          "
                        >
                          <p
                            class="error--text"
                            v-for="(
                              error, index
                            ) in validation_errors.contact_person_mobile"
                            :key="index"
                          >
                            {{ $t(error) }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="email">{{ $t("Email") }}</label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="email"
                      v-model.trim="admission.contact_person_email"
                      @focus="onFocus()"
                      :rules="[
                        validationRules.required,
                        validationRules.maxLength50,
                        validationRules.email,
                      ]"
                      solo
                      dense
                    ></v-text-field>

                    <div
                      v-if="validation_errors.contact_person_email"
                      :class="
                        currentAppLocale == 'ar' ? 'right-text' : 'left-text'
                      "
                    >
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_person_email"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="form-item">
                  <v-col cols="12" md="4"
                    ><label for="nationality-options"
                      >{{ $t("Nationality") }}
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      :items="dataObject.nationalities"
                      item-text="name"
                      item-value="id"
                      v-model.trim="admission.contact_nationality_id"
                      :select="onSelectContact()"
                      :rules="[validationRules.required]"
                      solo
                    >
                    </v-autocomplete>
                    <div v-if="validation_errors.contact_nationality_id">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_nationality_id"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="form-item" v-if="saudiContact">
                  <v-col cols="12" md="4">
                    <label for="national-id">{{
                      $t("National ID")
                    }}</label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="national-id-contact"
                      v-model.trim="admission.contact_national_id"
                      :rules="[
                        validationRules.required,
                        validationRules.numeric,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div
                      v-if="validation_errors.contact_national_id"
                      :class="
                        currentAppLocale == 'ar' ? 'right-text' : 'left-text'
                      "
                    >
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_national_id"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                    <div
                      v-if="
                        admission.student_national_id != '' &&
                        admission.student_national_id ==
                          admission.contact_national_id
                      "
                    >
                      <p class="error--text">
                        {{ $t("This national id aleardy exist") }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="form-item" v-if="!saudiContact">
                  <v-col cols="12" md="4"
                    ><label>{{ $t("Iqama No") }} </label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="iqama-number-contact"
                      v-model.trim="admission.contact_iqama_no"
                      :rules="[
                        validationRules.required,
                        validationRules.alphaNum,
                        validationRules.spaces,
                      ]"
                      @focus="onFocus()"
                      solo
                      dense
                    ></v-text-field>

                    <div
                      v-if="validation_errors.contact_iqama_no"
                      :class="
                        currentAppLocale == 'ar' ? 'right-text' : 'left-text'
                      "
                    >
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_iqama_no"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item" v-if="!saudiContact">
                  <v-col cols="12" md="4">
                    <label>{{ $t("Expiry Date") }}</label>
                  </v-col>
                  <v-col cols="12" md="8" style="margin-top: -10px">
                    <v-radio-group v-model="contact_iqama_no_date_type">
                      <v-row>
                        <v-col cols="6" md="6" sm="6"
                          ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                        ></v-col>
                        <v-col cols="6" md="6" sm="6"
                          ><v-radio
                            value="gregorian"
                            :label="$t('Gregorian')"
                          ></v-radio
                        ></v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="4" style="margin-top: -20px"></v-col>
                  <v-col cols="12" md="8" style="margin-top: -20px">
                    <v-text-field
                      id="iqama_geo-contact"
                      @focus="onFocus()"
                      v-show="contact_iqama_no_date_type == 'gregorian'"
                      autocomplete="off"
                      v-model.trim="admission.contact_iqama_no_expire_date"
                      :rules="[validationRules.required]"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>

                    <v-text-field
                      id="hijri2-contact"
                      v-show="contact_iqama_no_date_type == 'hijri'"
                      @focus="onFocus()"
                      autocomplete="off"
                      v-model.lazy="
                        admission.contact_iqama_no_expire_date_hijri
                      "
                      :rules="[validationRules.required]"
                      solo
                      dense
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                    ></v-text-field>

                    <div
                      v-if="validation_errors.contact_iqama_no_expire_date"
                      :class="
                        currentAppLocale == 'ar' ? 'right-text' : 'left-text'
                      "
                    >
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_iqama_no_expire_date"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="pa-0 right-sec"
                :class="currentAppLocale == 'ar' ? 'right-col-page' : ''"
              >
                <v-row class="form-item">
                  <v-col cols="12" md="4">
                    <label for="relationship-option">{{
                      $t("Relationship")
                    }}</label></v-col
                  >
                  <v-col cols="12" md="8">
                    <v-radio-group
                      v-model="admission.contact_person_relationship"
                      row
                      @change="onOther()"
                      :rules="[validationRules.required]"
                    >
                      <v-radio
                        :label="$t('Father')"
                        value="father"
                        id="father"
                      ></v-radio>
                      <v-radio
                        :label="$t('Mother')"
                        value="mother"
                        id="mother"
                      ></v-radio>
                      <!-- <v-radio label="Uncle" value="uncle" id="uncle"></v-radio>
                      <v-radio label="Other" value="other" id="other"></v-radio> -->
                    </v-radio-group>

                    <div v-if="validation_errors.contact_person_relationship">
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_person_relationship"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <!-- <v-row class="form-item" v-if="other">
                  <v-col cols="12" md="4"> </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      id="other"
                      v-model="admission.contact_person_relationship_other"
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                      dense
                    ></v-text-field>

                    <div
                      v-if="validation_errors.contact_person_relationship_other"
                    >
                      <p
                        class="error--text"
                        v-for="(error,
                        index) in validation_errors.contact_person_relationship_other"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row> -->

                <v-row class="form-item checkbox">
                  <v-col cols="12" md="4"
                    ><label style
                      >{{ $t("Preferred method of communication") }}
                    </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-row justify="start">
                      <v-checkbox
                        class="mx-2"
                        :label="$t('SMS')"
                        id="sms"
                        value="sms"
                        v-model="admission.contact_person_communication_method"
                      ></v-checkbox>

                      <v-checkbox
                        class="mx-2"
                        :label="$t('E-mail')"
                        id="email"
                        value="email"
                        v-model="admission.contact_person_communication_method"
                      ></v-checkbox>
                    </v-row>

                    <div
                      v-if="
                        validation_errors.contact_person_communication_method
                      "
                    >
                      <p
                        class="error--text"
                        v-for="(
                          error, index
                        ) in validation_errors.contact_person_communication_method"
                        :key="index"
                      >
                        {{ $t(error) }}
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="form-item">
                  <v-checkbox
                    v-model="admission.contact_person_information_certify"
                    :rules="[validationRules.required]"
                    class="mx-2"
                    :label="
                      $i18n.t(
                        'I hereby certify that the information provided above is true and correct'
                      )
                    "
                  ></v-checkbox>

                  <div
                    style="width: 100%"
                    v-if="validation_errors.contact_person_information_certify"
                  >
                    <p
                      class="error--text"
                      v-for="(
                        error, index
                      ) in validation_errors.contact_person_information_certify"
                      :key="index"
                    >
                      {{ $t(error) }}
                    </p>
                  </div>
                </v-row>
              </v-col>
            </v-row>

            <div class="text-center">
              <button @click.prevent="submitAdmission()" class="ui-btn submit">
                {{ $t("Apply") }}
              </button>
            </div>
          </section>
        </v-form>
        <!-- </form> -->
      </v-col>
    </v-row>

    <!-- Next- previous pagination -->
    <v-row class="pagination mx-16" :class="{ hidden: success }">
      <v-col :class="currentAppLocale == 'ar' ? 'text-right' : ''">
        <v-icon
          @click="before"
          class="before"
          v-if="currentAppLocale == 'en'"
          :disabled="this.page == 1 ? true : false"
          color="white"
          >mdi-arrow-left-thick</v-icon
        >
        <v-icon
          @click="before"
          v-else
          class="before"
          :disabled="this.page == 1 ? true : false"
          color="white"
          >mdi-arrow-right-thick</v-icon
        >
        <span class="dark-gray">{{ $t("Previous") }}</span>
      </v-col>
      <v-col :class="currentAppLocale == 'en' ? 'text-right' : ''">
        <span class="main-color">{{ $t("Next") }}</span>
        <v-icon @click="next" class="next" v-if="currentAppLocale == 'en'"
          >mdi-arrow-right-thick</v-icon
        >
        <v-icon @click="next" class="next" v-else>mdi-arrow-left-thick</v-icon>
      </v-col>
      <!-- <v-radio-group v-model="page" row>
            <v-radio
              v-for="n in 3"
              :key="n"
              :value="n"
              :disabled="checktab(n)"
            ></v-radio>
          </v-radio-group> -->
    </v-row>

    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import { AdmissionMixin } from "../../mixins/AdmissionMixin";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "Admission",
  mixins: [AdmissionMixin, validationMixin],
  data() {
    return {
      step: 1,
      snack: false,
      snackTime: 3000,
      snackText: "",
      snackColor: "",
      logo: "",
      success: false,

      valid1: true,
      valid2: true,
      valid3: true,
      saudi: true,

      disabledSubmitbtn: false,
      successMsg: "",
      requestNum: "",
      applicant_name: "",
      other: false,
      gradeError: false,
      page: 1,
      page1: true,
      page2: false,
      page3: false,

      // page1: false,
      // page2: false,
      // page3: true,
      hideLabel: false,
      admission: { country_code: 187 },
      religions: [
        { key: "Muslim", value: "muslim" },
        { key: "Non-muslim", value: "non-muslim" },
      ],
      languages: [
        { key: this.$i18n.t("English"), value: 1 },
        { key: this.$i18n.t("Arabic"), value: 2 },
      ],
    };
  },
  watch: {
    page: function (v) {
      this.showPage(v);
    },
  },
  computed: {
    is_screen_small() {
      console.log("small" + this.$vuetify.breakpoint.smOnly);
      return this.$vuetify.breakpoint.smOnly;
    },
  },
  methods: {
    nextStep(page) {
      // if (this.step != 1 && page != 3) {
      //   //
      // } else {
      if (page > this.page) {
        this.next();
      } else if (page < this.page) {
        this.before(page);
      }
      // }
    },
    showPage(id) {
      if (id == 1) {
        this.page1 = true;
        this.page2 = false;
        this.page3 = false;
      }
      if (id == 2) {
        this.page2 = true;
        this.page1 = false;
        this.page3 = false;
      }
      if (id == 3) {
        this.page3 = true;
        this.page1 = false;
        this.page2 = false;
      }
    },

    next() {
      console.log(this.page);
      if (this.page == 1) {
        if (this.valid1) {
          this.page++;
          this.step = Number(this.step) + 1;
        } else {
          this.$refs.form1.validate();
          this.step = 1;
        }
      } else if (this.page == 2) {
        if (this.valid2 && this.gradeError == false) {
          this.page++;
          this.step = Number(this.step) + 1;
        } else {
          this.$refs.form2.validate();
          this.step = 2;
        }
      }
    },
    before(step) {
      if (this.page > 1) {
        this.page--;
        if (Number(step)) {
          this.step = step;
        } else {
          this.step = this.page;
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.lang != undefined) {
      this.currentAppLocale = this.$route.query.lang;
    } else {
      this.currentAppLocale = "en";
    }
    axios
      .post(
        this.getApiUrl + "/applicant/checkAdmission",
        { alias: this.$router.currentRoute.params.alias },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        }
      )
      .then((response) => {
        if (response.data.data.check == false) {
          // redirect to not found
          // this.$router.push({
          //   name: "NotFoundComponent"
          // });
        }
      });

    axios.get(this.getApiUrl + "/getLogo", {}).then((response) => {
      if (response.data.data != null) {
        this.logo = response.data.data.logo;
        // console.log(this.logo);
      }
    });

    // this.logo = localStorage.logo;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.admission-title {
  color: #9a9797;
  font-weight: bold;
  padding: 0 40px;
}
.studentInfo-title {
  font-weight: bold;
  color: #9a9797;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.nationality,
.religion,
.native,
.secondLang,
.grade {
  position: relative;
}

.select-icon {
  position: absolute;
  top: 56px;
  right: 16px;
}

.success-icon {
  font-size: 100px;
}

.message {
  width: 80%;
  padding: 35px;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 17px #d1d1d1 !important;
  box-shadow: 0px 3px 17px #d1d1d1 !important;
  margin: 0 auto;
}

.svg-sec {
  background-color: $main-color;
  margin: 0 auto;
  svg {
    height: 200px;
  }
}

.right-sec {
  .form-item {
    // text-align: right;
    margin-left: 50px;
  }
}

.right-col-page {
  text-align: right;
  padding-right: 150px !important;
}

label {
  color: #9a9797;
}

.pagination {
  .before {
    color: white !important;
    width: 30px;
    height: 30px;
    background-color: $main-color;
    border-radius: 50%;
    margin: 0 5px;
  }
  .next {
    color: white !important;
    width: 30px;
    height: 30px;
    background-color: $main-color;
    border-radius: 50%;
    margin: 0 5px;
  }
}

.theme--light.v-icon.v-icon.v-icon--disabled {
  color: #fff !important;
  background-color: #9a9797 !important;
}
</style>
